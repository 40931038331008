/* eslint-disable jsx-a11y/click-events-have-key-events */
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { styled, theme } from "src/styles/stitches/theme";
import { Text } from "src/components/01_atoms/text";
import { Input } from "src/components/01_atoms/input";
import { buildVisitMotivesData, getLocalizedValue, translateVisitMotivesToString } from "./utils";
import useVisitMotives from "src/hooks/use-visit-motives";
import { VisitMotive } from "src/types";
import { VisitMotiveItem } from "../visit-motive-item/visit-motive-item";
import { Icon } from "src/components/01_atoms/icon";
import { matchIsAccidentClaimCategory } from "src/helpers";
import { EmptyVisitMotiveList } from "../empty-visit-motive-list/empty-visit-motive-list";

interface VisitMotivesInputProps {
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  fieldName: string;
  claimCategory: string;
}

export const VisitMotivesInput = ({
  setValue,
  watch,
  fieldName,
  claimCategory,
}: VisitMotivesInputProps) => {
  const [searchValue, setSearchValue] = useState("");
  const { visitMotives, visitMotiveTranslation } = useVisitMotives();
  const keyWords = visitMotives.reduce(
    (acc: string[], currentMotive: VisitMotive) => [
      ...acc,
      ...currentMotive.formatted_key_words_fr,
    ],
    []
  );

  const filteredVisitMotives = useMemo(() => {
    return buildVisitMotivesData(claimCategory, visitMotives, searchValue, keyWords);
  }, [claimCategory, visitMotives, searchValue, keyWords]);

  const onVisitMotiveToggle = (value: string) => {
    const currentList = watch(fieldName) || [];
    const valueIndex = currentList.indexOf(value);

    if (valueIndex === -1) {
      currentList.push(value);
    } else {
      currentList.splice(valueIndex, 1);
    }
    setValue(fieldName, currentList, { shouldValidate: true });
  };

  const visitMotiveList =
    searchValue.length >= 1
      ? filteredVisitMotives
      : buildVisitMotivesData(claimCategory, visitMotives, searchValue, keyWords);

  const handleRemoveVisitMotive = useCallback(
    (indexToRemove: number) => {
      const updatedValues = watch(fieldName)?.filter(
        (_: string, index: number) => index !== indexToRemove
      );
      setValue(fieldName, updatedValues, { shouldValidate: true });
    },
    [watch, fieldName, setValue]
  );

  const getSearchTitleText = () => {
    if (searchValue.length) {
      return `Résultats pour "${searchValue}"`;
    } else if (matchIsAccidentClaimCategory(claimCategory)) {
      return "Accident(s)";
    } else {
      return "Maladie(s)";
    }
  };

  return (
    <>
      <Label variant="caption1-Medium" color="textBaseSubdued">
        {matchIsAccidentClaimCategory(claimCategory)
          ? "Motif(s) de l’accident"
          : "Nom(s) de la maladie"}
      </Label>
      <Container>
        <Input
          value={
            searchValue
              ? searchValue
              : translateVisitMotivesToString(watch(fieldName), visitMotiveTranslation)
          }
          leftIcon="outlinedSearch"
          placeholder="Sélectionner un ou plusieurs motifs"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {watch(fieldName) && watch(fieldName).length ? (
          <SelectedMotiveListView>
            {watch(fieldName)?.map((visitMotive: string, index: number) => {
              return (
                <div key={index}>
                  <BadgeContainer>
                    <BadgeTitle variant="caption1-Bold">
                      {getLocalizedValue(visitMotive, visitMotiveTranslation)}
                    </BadgeTitle>
                    <WrapperIcon onClick={() => handleRemoveVisitMotive(index)}>
                      <StyledIcon name="filledClose" size="18" />
                    </WrapperIcon>
                  </BadgeContainer>
                </div>
              );
            })}
          </SelectedMotiveListView>
        ) : null}
        <Title variant="display4-Bold" color="textBaseSubdued">
          {getSearchTitleText()}
        </Title>
        <VisitMotiveList>
          {visitMotiveList.length >= 1 ? (
            visitMotiveList.map((visitMotive, index) => {
              return (
                <VisitMotiveItem
                  isLastItem={index === visitMotiveList.length - 1}
                  key={index}
                  index={visitMotive}
                  label={visitMotive}
                  isChecked={watch(fieldName)?.includes(visitMotive)}
                  onPress={() => onVisitMotiveToggle(visitMotive)}
                />
              );
            })
          ) : (
            <EmptyVisitMotiveList />
          )}
        </VisitMotiveList>
      </Container>
    </>
  );
};

const Container = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "column",
});

const SelectedMotiveListView = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  minHeight: theme.spacing.spacing32px,
  marginTop: theme.spacing.spacing16px,
  gap: "12px",
});

const BadgeTitle = styled(Text, {
  color: "white !important",
  marginRight: theme.spacing.spacing8px,
});

const BadgeContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: 100,
  paddingVertical: theme.spacing.spacing8px,
  paddingLeft: theme.spacing.spacing12px,
  paddingRight: theme.spacing.spacing12px,
  backgroundColor: theme.colors.buttonBackgroundAccentDefault,
  height: theme.spacing.spacing32px,
});

const VisitMotiveList = styled("div", {
  maxHeight: "300px",
  overflowY: "auto",
});

const Label = styled(Text, {
  marginBottom: theme.spacing.spacing8px,
});

const Title = styled(Text, {
  marginTop: theme.spacing.spacing36px,
  marginBottom: theme.spacing.spacing12px,
});

const StyledIcon = styled(Icon, {
  fill: "white",
});

const WrapperIcon = styled("div", {
  cursor: "pointer",
});
