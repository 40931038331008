import { useActor } from "@xstate/react";
import copy from "copy-to-clipboard";
import { toast } from "src/components/01_atoms/toast";
import { SidesheetScreenWithImage } from "src/components/02_molecules/sidesheet-screen-with-image";
import { SidesheetLayout } from "src/components/04_layouts/sidesheet-layout";
import { matchIsAllContractsDeffered } from "src/helpers";
import { useGetContact, useGetContract } from "src/hooks/data";
import { useWebShare } from "src/hooks/useWebShare";
import { onboardingStepsService } from "src/store/onboarding/machine";
import { getFormattedDateWithYear } from "src/utils/format";
import { OnboardingForm } from "./form";
import { OnboardingHeader } from "./header";
import { Iban } from "./iban";
import { StyledContainer } from "./index.styled";

type Props = {
  isOpen: boolean;
  setIsOnboardingOpen: (isOnboardingOpen: boolean) => void;
  setIsDownloadAppModalOrDrawerOpen: (isDownloadAppModalOrDrawerOpen: boolean) => void;
};

export function Onboarding({
  isOpen,
  setIsOnboardingOpen,
  setIsDownloadAppModalOrDrawerOpen,
}: Props) {
  const [state, send] = useActor(onboardingStepsService);
  const { data: contract } = useGetContract();

  const { data: contact, isLoading } = useGetContact();
  const { isSupported, share } = useWebShare();

  const stateValue = state.value;

  if (typeof stateValue !== "string") {
    return null;
  }

  const currentStep = stateValue;

  if (!contact) return null;

  const onboardingPagesByStep: {
    [step: string]: JSX.Element;
  } = {
    welcome: (
      <SidesheetScreenWithImage
        title="Bienvenue chez Dalma !"
        description="La super app des chiens et chats heureux"
        image="assets/media/humans-cat-dog-man.svg"
        imageAlt="Illustration d'un homme avec un chat et un chien"
        onClickButton1={() => {
          send("NEXT_STEP");
        }}
        buttonLabel1="Commencer"
      />
    ),
    iban: (
      <Iban
        isFromOnboarding={true}
        onClickButton1={() => {
          send("NEXT_STEP");
        }}
      />
    ),
    raf:
      contract !== undefined && matchIsAllContractsDeffered(contract) ? (
        <SidesheetScreenWithImage
          title="Offrez 1 mois, recevez 25 €"
          description="Parrainez vos amis et embarquez-les dans l’aventure Dalma grâce à votre code parrain lorsque votre contrat sera actif."
          image="assets/media/gift.svg"
          imageAlt="Illustration d'un cadeau"
          onClickButton1={async () => {
            send("NEXT_STEP");
            return;
          }}
          buttonLabel1="Continuer"
          onClickButton2={() => {
            send("NEXT_STEP");
          }}
        />
      ) : (
        <SidesheetScreenWithImage
          title="Offrez 1 mois, recevez 25 €"
          description={`Parrainez vos amis et embarquez-les dans l’aventure Dalma grâce à votre code parrain ${contact.raf_code}`}
          image="assets/media/gift.svg"
          imageAlt="Illustration d'un cadeau"
          onClickButton1={async () => {
            if (isSupported) {
              await share({
                text: `Parrainez vos amis et embarquez-les dans l’aventure Dalma grâce à votre code parrain ${contact.raf_code}`,
                title: "Partager mon lien de parrainage",
                url: `https://parrain.dalma.co/?raf_code=${encodeURI(
                  contact.raf_code
                )}&utm_source=RAF&utm_medium=&utm_campaign=page-filleul`,
              });
              send("NEXT_STEP");
              return;
            }
            copy(contact.raf_code);
            toast.copy("Le code a été copié dans le presse-papier");
            send("NEXT_STEP");
          }}
          buttonLabel1={isSupported ? "Partager le code" : " Copier le code"}
          isLoadingButton1={isLoading}
          onClickButton2={() => {
            send("NEXT_STEP");
          }}
          buttonLabel2="Non merci"
        />
      ),
    howDidYouKnowDalma: (
      <OnboardingForm
        currentStep={currentStep}
        setIsDownloadAppModalOrDrawerOpen={setIsDownloadAppModalOrDrawerOpen}
      />
    ),

    vetClinic: (
      <OnboardingForm
        currentStep={currentStep}
        setIsDownloadAppModalOrDrawerOpen={() => {
          if (contract !== undefined && matchIsAllContractsDeffered(contract)) {
            send("NEXT_STEP");
            setIsDownloadAppModalOrDrawerOpen(false);
          } else {
            setIsOnboardingOpen(false);
            setIsDownloadAppModalOrDrawerOpen(true);
          }
        }}
      />
    ),

    deferred: (
      <SidesheetScreenWithImage
        title="Certaines fonctionnalités seront indisponibles."
        description={`Les demandes de remboursement, le parrainage ainsi que l’échange avec des vétérinaires ne seront disponibles qu’une fois votre contrat actif. Pour rappel, celui-ci débute le ${getFormattedDateWithYear(
          contract ? contract[0]?.contract.start_date.toString() : new Date().toString()
        )}.`}
        image="assets/media/stop-sign-illu.svg"
        imageAlt="Illustration d'un panneau stop"
        onClickButton1={() => {
          setIsOnboardingOpen(false);
          setIsDownloadAppModalOrDrawerOpen(true);
        }}
        buttonLabel1="Continuer"
      />
    ),
  };

  const getCurrentStepPage = (currentStep: string) => {
    return onboardingPagesByStep[currentStep];
  };

  return (
    <SidesheetLayout isOpen={isOpen} label="Onboarding">
      {currentStep !== "welcome" && currentStep !== "raf" ? (
        <StyledContainer>
          <OnboardingHeader />
          {getCurrentStepPage(currentStep)}
        </StyledContainer>
      ) : (
        getCurrentStepPage(currentStep)
      )}
    </SidesheetLayout>
  );
}
