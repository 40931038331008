import { API, Auth } from "aws-amplify";
import { Contact } from "src/types";

export function referAFriend(godSonEmail: string, contact: Contact) {
  const apiName = "apiOldMySpaceGateway";
  const path = "/raf";

  Auth.currentAuthenticatedUser()
    .then((user) => {
      const token = user.signInUserSession.idToken.jwtToken;
      const email = user.attributes.email.toLowerCase();

      const myInit = {
        headers: { Authorization: token },
        response: false,
        body: {
          email: godSonEmail,
          contact_properties: {
            email: godSonEmail,
          },
          deal_properties: {
            source_du_lead: "RAF",
            dealname: "RAF-" + email + "-" + godSonEmail,
            prenom_et_nom_du_parrain: [contact.firstname, contact.lastname].join(" "),
            code_raf_parrain: contact.raf_code,
          },
        },
      };

      API.post(apiName, path, myInit)
        .then(() => {
          // console.log("API :: ", path, response);
          // alert('Parrainage : Un email a été envoyé à ' + godSonEmail);
        })
        .catch(() => {
          // console.log(error.response);
        });
    })
    .catch(() => {
      // console.log("err", err);
    });
}
