/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text } from "src/components/01_atoms/text";
import { styled, theme } from "src/styles/stitches/theme";

export const EmptyVisitMotiveList = () => {
  return (
    <SearchEmptyResultContainer>
      <Illustration
        src="/assets/media/illu-dog-running.svg"
        alt="Illustration d'un chien qui court"
      />
      <NoResultText variant="paragraph2-Medium" color="textBaseSubdued">
        Oops, nous n’avons pas trouvé ce que vous cherchez. Pensez à vérifier l’orthographe.
      </NoResultText>
    </SearchEmptyResultContainer>
  );
};

const SearchEmptyResultContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingHorizontal: theme.spacing.spacing28px,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const NoResultText = styled(Text, {
  marginBottom: theme.spacing.spacing12px,
});

const Illustration = styled("img", {
  width: "10rem",
  height: "10rem",
});
