import { matchIsAccidentClaimCategory } from "src/helpers";
import { VisitMotive, VisitMotiveTranslation } from "src/types";
import Fuse from "fuse.js";

function normalizeSearch(searchValue: string) {
  return searchValue
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[',&/?]/g, "");
}

export function translateVisitMotivesToString(
  visitMotives: string[],
  translationMap: { [key: string]: { [key: string]: string } }
): string {
  const currentLanguage = "fr";
  return visitMotives
    ?.map((motive) => translationMap[currentLanguage][motive] || motive)
    .join(", ");
}
export function getLocalizedValue(item: string, translation: VisitMotiveTranslation) {
  if (translation && translation.fr && translation.fr[item]) {
    return translation.fr[item];
  } else {
    return item;
  }
}

const fuseOptions = {
  includeScore: true,
  threshold: 0.2,
};

function getDiseaseVisitMotive(
  keyWords: string[],
  visitMotives: VisitMotive[],
  searchValue: string
) {
  if (!searchValue.trim()) {
    return visitMotives
      .filter((item) => item.consultation_types.includes("MALADIE"))
      .reduce(
        (acc: string[], current: VisitMotive) => [...acc, ...current.formatted_key_words_fr],
        []
      );
  }
  const fuse = new Fuse(keyWords, fuseOptions);
  const results = fuse.search(searchValue).map((result) => result.item);

  return results;
}

function getAccidentVisitMotive(
  keyWords: string[],
  visitMotives: VisitMotive[],
  searchValue: string
) {
  if (!searchValue.trim()) {
    return visitMotives
      .filter((item) => item.consultation_types.includes("ACCIDENT"))
      .reduce(
        (acc: string[], current: VisitMotive) => [...acc, ...current.formatted_key_words_fr],
        []
      );
  }
  const fuse = new Fuse(keyWords, fuseOptions);
  const results = fuse.search(searchValue).map((result) => result.item);

  return results;
}

export function buildVisitMotivesData(
  claimCategory: string,
  visitMotives: VisitMotive[],
  searchValue: string,
  keyWords: string[]
) {
  return matchIsAccidentClaimCategory(claimCategory)
    ? getAccidentVisitMotive(keyWords, visitMotives, normalizeSearch(searchValue))
    : getDiseaseVisitMotive(keyWords, visitMotives, normalizeSearch(searchValue));
}

export function formatArrayAsString(array: Array<string> | undefined) {
  if (!array) {
    return "";
  }

  return array
    .map((item) =>
      item
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
    )
    .join(", ");
}

export function formatCauseValue(causeValue: string) {
  return causeValue.toUpperCase().replace(/ /g, "_");
}
