import { Box, Input, FormLabel, FormControl, FormErrorMessage } from "@chakra-ui/react";
import "./styles/TextField.css";

type TextFieldProps = {
  autocomplete?: string;
  label?: string;
  placeholder?: string;
  w?: React.ComponentProps<typeof Box>["w"];
  h?: string;
  inputH?: string;
  bg?: string;
  boxShadow?: string;
  borderRadius?: string;
  borderColor?: string;
  color?: string;
  placeholderColor?: string;
  placeholderSize?: string;
  fontWeight?: string;
  fontSize?: string;
  fontFamily?: string;
  name?: string;
  errorsMessage?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  validateFunction?: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export const TextField = ({
  placeholder = "Entrez une valeur",
  w = "360px",
  h = "52px",
  inputH = "52px",
  borderRadius = "8px",
  boxShadow = "",
  bg = "secondBackground",
  color = "textfieldFont",
  placeholderColor = "#9E9E9E",
  placeholderSize = "sm",
  value = "",
  fontWeight = "400",
  fontFamily = "Grotesk",
  errorsMessage = "",
  name = "",
  autocomplete = "",
  disabled = false,
  handleChange,
  ...props
}: TextFieldProps) => (
  <Box h={h} w={w} mb={errorsMessage ? ["35px", "30px", "20px", "30px"] : "0"} {...props}>
    <Box id="float-label" h={h} w={w}>
      <FormControl id="pet-name" isInvalid={!!errorsMessage}>
        <Input
          outline="0"
          name={name}
          w={w}
          autoComplete={autocomplete}
          style={{
            height: inputH,
            backgroundColor: `${bg}`,
            borderRadius: `${borderRadius}`,
            borderColor: "radioTextBorder",
            borderWidth: "1px",
          }}
          value={value}
          fontFamily={fontFamily}
          h={inputH}
          _active={{
            outline: "0",
          }}
          boxShadow={boxShadow}
          color={color}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        <FormLabel
          h={inputH}
          className={value !== "" ? "active" : ""}
          style={{
            color: placeholderColor,
            fontSize: placeholderSize,
            fontWeight,
            fontFamily,
          }}
        >
          {placeholder}
        </FormLabel>
        <FormErrorMessage>{errorsMessage && errorsMessage}</FormErrorMessage>
      </FormControl>
    </Box>
  </Box>
);
