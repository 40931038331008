import { SubmitHandler, useForm } from "react-hook-form";
import { Outlet } from "src/components/01_atoms/outlet";
import { SidesheetContentHeader } from "src/components/02_molecules/sidesheet-content-header";
import { styled, theme } from "src/styles/stitches/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { Button } from "src/components/01_atoms/button";
import { useGetContract } from "src/hooks/data";
import { reportError } from "src/utils/error";
import { matchIsAccidentAndDiseaseCategory } from "src/helpers";
import { CausesDateInput } from "src/components/02_molecules/causes-date-input";
import { VisitMotivesInput } from "src/components/02_molecules/visit-motives-input/visit-motives-input";

const Form = styled("form", {
  flex: 1,
});

const StyledOutlet = styled(Outlet, {
  padding: `${theme.spacing.spacing32px} ${theme.spacing.spacing24px}`,
  flexDirection: "column",

  "@bp3": {
    padding: `0 ${theme.spacing.spacing32px} ${theme.spacing.spacing36px}`,
  },
});

const diseaseCausesSchema = z.object({
  first_symptom_date: z.number(),
  visit_motives_disease: z.array(z.string()).nonempty(),
});

type DiseaseCauses = {
  first_symptom_date: Date | undefined;
  visit_motives_disease: string[];
};

export function DiseaseCausesForm() {
  const {
    currentStep,
    setCurrentStep,
    selectedPetToContext,
    selectedConsultationTypes,
    selectedDiseaseDate,
    setSelectedDiseaseDate,
    visitMotivesDisease,
    setVisitMotivesDisease,
  } = useRefundSidebar();

  const { data: contracts } = useGetContract();

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<DiseaseCauses>({
    resolver: zodResolver(diseaseCausesSchema),
    mode: "onChange",
    defaultValues: {
      first_symptom_date: selectedDiseaseDate,
      visit_motives_disease: visitMotivesDisease,
    },
  });

  if (!contracts) {
    return null;
  }

  const currentPetContract = contracts?.find(
    (contract) => contract.contract.contract_uuid === selectedPetToContext?.contract_uuid
  );

  if (!currentPetContract) {
    reportError(
      `No current pet contract found on the disease causes form. selectedPet = ${selectedPetToContext?.contract_uuid}`
    );
    return null;
  }

  const onSubmit: SubmitHandler<DiseaseCauses> = (values) => {
    setVisitMotivesDisease(values.visit_motives_disease);
    setSelectedDiseaseDate(values.first_symptom_date);
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledOutlet vertical={{ "@initial": "spacing16px", "@bp3": "spacing16px" }} fullHeight>
          <SidesheetContentHeader
            title={
              matchIsAccidentAndDiseaseCategory(selectedConsultationTypes)
                ? "Et maintenant, dîtes-nous en plus sur la maladie"
                : "Dîtes-nous en plus sur la maladie"
            }
            subtitle="Demande de remboursement"
            alertIconName="filledInfo"
            alertText="Cette information nous permettra de traiter votre demande de remboursement plus rapidement"
          />
          <CausesDateInput
            setValue={setValue}
            fieldName="first_symptom_date"
            selectedDate={selectedDiseaseDate}
          />
          <VisitMotivesInput
            fieldName="visit_motives_disease"
            claimCategory="DISEASE"
            watch={watch}
            setValue={setValue}
          />
          <Outlet vertical="spacing32px" justify={{ "@bp3": "spaceBetween" }} fullHeight fluid>
            <Button type="submit" variant="accent" size="lg" fluid disabled={!isValid}>
              Continuer
            </Button>
          </Outlet>
        </StyledOutlet>
      </Form>
    </>
  );
}
