import { createStitches, globalCss } from "@stitches/react";

/* Color styles as RGB values */
export const baseColors = Object.freeze({
  neutral000: "255, 255, 255",
  neutral100: "241, 247, 255",
  neutral200: "233, 244, 254",
  neutral300: "219, 235, 255",
  neutral400: "194, 216, 240",
  neutral500: "103, 138, 184",
  neutral600: "69, 112, 169",
  neutral700: "40, 89, 154",
  neutral800: "20, 66, 131",
  neutral900: "16, 21, 49",
  neutral999: "0, 0, 0",
  blue300: "219, 235, 255",
  blue400: "166, 180, 250",
  blue500: "78, 104, 246",
  blue600: "71, 95, 229",
  blue700: "50, 71, 188",
  green300: "221, 240, 234",
  green400: "128, 184, 167",
  green500: "0, 112, 79",
  orange300: "250, 238, 230",
  orange400: "255, 183, 139",
  orange500: "254, 111, 24",
  yellow300: "255, 242, 224",
  yellow400: "254, 218, 128",
  yellow500: "255, 180, 0",
  pink300: "255, 235, 245",
  pink400: "250, 198, 227",
  pink500: "252, 156, 206",
  red300: "250, 234, 235",
  red400: "245, 209, 211",
  red500: "214, 68, 76",
  red600: "185, 43, 51",
  red700: "163, 22, 29",
  grey300: "241, 241, 243",
  grey400: "225, 226, 230",
  grey500: "112, 115, 131",
  grey600: "121, 121, 121",

  // Color replacing gradients for texts and icons
  green: "#449CB4",
  lightBlue: "#3980EB",
  blueMuted: "103, 138, 184",
  purple: "#817DEC",
  orange: "#DF6B00",
  red: "#E44E59",
  dalmaBlue: "#0B2AD6",
  dalmaBlueNoGradient: "#0B2AD6",
  yellow: "#ECCC7E",
  linkBlue: "#4E68F6",
});

const gradient = Object.freeze({
  // TODO use basecolors
  gradientGreen: "radial-gradient(113.7% 275.98% at 106.14% 105.19%, #6ED5BE 0%, #449CB4 100%)",
  gradientLightBlue: "radial-gradient(113.7% 275.98% at 106.14% 105.19%, #55BAF4 0%, #3980EB 100%)",
  gradientPurple: "radial-gradient(113.7% 275.98% at 106.14% 105.19%, #817DEC 0%, #0B1C83 100%)",
  gradientOrange: "radial-gradient(113.7% 275.98% at 106.14% 105.19%, #ECB27D 0%, #DF6B00 100%)",
  gradientRed: "radial-gradient(113.7% 275.98% at 106.14% 105.19%, #DD8CB3 0%, #E44E59 100%)",
  gradientDalmaBlue: "radial-gradient(113.7% 275.98% at 106.14% 105.19%, #4E68F6 0%, #0B2AD6 100%)",
  gradientYellow: "radial-gradient(113.7% 275.98% at 106.14% 105.19%, #ECCC7E 0%, #FFB400 100%)",
  gradientAngular:
    "conic-gradient(from 90deg at 50% 50%, rgba(81, 177, 243, 0) 0deg, #3E8AED 360deg)",
  gradientWhite: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
  gradientRadialWhite:
    "conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 0) 0deg, #FFFFFF 360deg)", // ?? Called Radial but Conic
});

export function rgb(color: string) {
  return `rgb(${color})`;
}

export function rgba(color: string, opacity: number) {
  return `rgba(${color}, ${opacity})`;
}

// Root size : 16px
const fonts = Object.freeze({
  // Family
  familyTitle: "Roslindale",
  familyText: "Scto Grotesk A",
});

const fontSizes = Object.freeze({
  // Font Sizes
  /** 48px */
  titleDisplay1: "3rem",
  /** 32px */
  titleDisplay2: "2rem",
  /** 24px */
  titleDisplay3: "1.5rem",
  /** 20px */
  titleDisplay4: "1.25rem",
  /** 18px */
  titleDisplay5: "1.12rem",

  /** 36px */
  textDisplay1: "2.25rem",
  /** 32px */
  textDisplay2: "2rem",
  /** 26px */
  textDisplay3: "1.62rem",
  /** 24px */
  textDisplay4: "1.5rem",
  /** 18px */
  textDisplay5: "1.25rem",
  /** 16px */
  textParagraph1: "1rem",
  /** 14px */
  textParagraph2: "0.88rem",
  /** 13px */
  textParagraph3: "0.81rem",
  /** 12px */
  textCaption1: "0.75rem",
  /** 10px */
  textCaption2: "0.62rem",
  /** 8px */
  textCaption3: "0.5rem",

  /** 14px */
  buttonLabel: "0.88rem",
  /** 12px */
  buttonLabelSmall: "0.75rem",
});

const shadows = Object.freeze({
  elevation1Purple: "0px 16px 32px rgba(138, 151, 221, 0.24)",
  elevation1Primary: "0px 16px 32px rgba(83, 138, 244, 0.24)",
  elevation2Primary: "0px 16px 32px rgba(83, 138, 244, 0.52)",
  elevation1Yellow: "0px 16px 32px rgba(254, 177, 0, 0.24)",
  elevation1Dark: "0px 16px 32px rgba(12, 46, 95, 0.24)",
  elevation1Light: "0px 0px 32px rgba(255, 255, 255, 0.6)",
});

export const { theme, styled, css, keyframes } = createStitches({
  media: {
    bp1: "(min-width: 576px)",
    bp2: "(min-width: 768px)",
    bp3: "(min-width: 1024px)",
    bp4: "(min-width: 1440px)",
    withHover: "(hover: hover) and (pointer: fine)",
  },
  theme: {
    colors: {
      backgroundPrimary: rgb(baseColors.neutral000),
      backgroundAccent: gradient.gradientDalmaBlue,
      backgroundAccentNoGradient: baseColors.dalmaBlueNoGradient,
      backgroundSecondaryMobile: rgb(baseColors.neutral000),
      backgroundSecondaryDesktop: rgb(baseColors.neutral000),
      backgroundNeutral: rgb(baseColors.neutral900),

      textBaseDefault: rgb(baseColors.neutral900),
      textBaseSubdued: rgb(baseColors.neutral500),
      textBaseDisabled: rgb(baseColors.neutral300),
      textPrimaryDefault: rgb(baseColors.blue500),
      textPrimaryDisabled: rgb(baseColors.blue400),
      textAccent: baseColors.dalmaBlue, //gradient.gradientDalmaBlue,
      textSuccess: rgb(baseColors.green500),
      textSuccesAccent: baseColors.green, //gradient.gradientGreen,
      textSuccessDisabled: rgb(baseColors.green400),
      textCritical: rgb(baseColors.red500),
      textCriticalAccent: baseColors.red, //gradient.gradientRed,
      textCriticalDisabled: rgb(baseColors.red400),
      textProgress: baseColors.lightBlue, //gradient.gradientLightBlue,
      textWarning: baseColors.orange, //gradient.gradientOrange,
      textWarningDisabled: rgb(baseColors.orange400),
      textSecondaryDefault: rgb(baseColors.neutral000),
      textSecondarySubdued: rgb(baseColors.neutral400),
      textSecondaryDisabled: rgba(baseColors.neutral000, 0.3),
      textLinkBlue: baseColors.linkBlue, //gradient.gradientLinkBlue,

      iconBaseDefault: rgb(baseColors.neutral900),
      iconBaseSubdued: rgb(baseColors.neutral500),
      iconBaseDisabled: rgb(baseColors.neutral300),
      iconGreyDefault: rgb(baseColors.grey600),
      iconPrimaryDefault: rgb(baseColors.blue500),
      iconPrimaryHovered: rgb(baseColors.neutral600),
      iconPrimaryPressed: rgb(baseColors.neutral700),
      iconPrimaryDisabled: rgb(baseColors.blue400),
      iconPrimaryMuted: rgb(baseColors.blueMuted),
      iconAccent: baseColors.dalmaBlue, //gradient.gradientDalmaBlue,
      iconSecondaryDefault: rgb(baseColors.neutral000),
      iconSecondarySubdued: rgb(baseColors.neutral400),
      iconSecondaryDisabled: rgba(baseColors.neutral000, 0.3),
      iconSuccess: rgb(baseColors.green500),
      iconWarning: baseColors.orange, //gradient.gradientOrange,
      iconCritical: rgb(baseColors.red500),
      iconDecorativeGreen: baseColors.green, //gradient.gradientGreen,
      iconDecorativeLightBlue: baseColors.lightBlue, //gradient.gradientLightBlue,
      iconDecorativeRed: baseColors.red, //gradient.gradientRed,
      iconDecorativePurple: baseColors.purple, //gradient.gradientPurple,

      borderBaseDefault: rgb(baseColors.neutral500),
      borderBaseSubdued: rgb(baseColors.neutral300),
      borderBaseDisabled: rgb(baseColors.neutral400),
      borderSecondary: rgb(baseColors.neutral000),
      borderSecondaryPressed: rgb(baseColors.neutral400),
      borderSelected: rgb(baseColors.blue500),
      borderCriticalDefault: rgb(baseColors.red500),
      borderCriticalSubdued: rgb(baseColors.red400),
      borderSuccessDefault: rgb(baseColors.green500),
      borderSuccessSubdued: rgb(baseColors.green400),
      fillBaseAccent: rgb(baseColors.neutral900),
      fillBaseDefault: rgb(baseColors.neutral500),
      fillBaseSubdued: rgb(baseColors.neutral300),
      fillBaseHovered: rgb(baseColors.neutral200),
      fillBasePressed: rgb(baseColors.neutral300),
      fillBaseDisabled: rgb(baseColors.neutral100),
      fillOverlay: rgba(baseColors.neutral800, 0.5),
      fillPrimaryDefault: rgb(baseColors.blue500),
      fillPrimaryHovered: rgb(baseColors.blue600),
      fillPrimaryPressed: rgb(baseColors.blue700),
      fillPrimarySubdued: rgba(baseColors.blue500, 0.2),
      fillPrimaryDisabled: rgba(baseColors.blue500, 0.6),
      fillAccent: gradient.gradientDalmaBlue,
      fillSecondary: rgb(baseColors.neutral000),
      fillSecondarySubdued: rgba(baseColors.neutral000, 0.8),
      fillSecondaryPressed: rgb(baseColors.neutral100),
      fillSuccessAccent: gradient.gradientGreen,
      fillSuccessSubdued: rgb(baseColors.green300),
      fillWarning: gradient.gradientOrange,
      fillWarningSubdued: rgb(baseColors.orange300),
      fillCriticalAccent: gradient.gradientRed,
      fillCriticalSubdued: rgb(baseColors.red300),
      fillDecorativeGreen: gradient.gradientGreen,
      fillDecorativeLightBlue: gradient.gradientLightBlue,
      fillDecorativeRed: gradient.gradientRed,
      fillDecorativePurple: gradient.gradientPurple,
      fillDecorativeYellow: gradient.gradientYellow,
      fillDecorativeWhite: gradient.gradientWhite,
      buttonBackgroundAccentDefault: rgb(baseColors.blue500),
      buttonBackgroundAccentDisabled: rgb(baseColors.blue400),
      buttonBackgroundAccentHovered: rgb(baseColors.blue600),
      buttonBackgroundAccentPressed: rgb(baseColors.blue700),
      buttonBackgroundSecondaryDefault: rgb(baseColors.neutral000),
      buttonBackgroundSecondaryHovered: rgb(baseColors.neutral100),
      buttonBackgroundSecondaryPressed: rgb(baseColors.neutral200),
      buttonBackgroundSecondaryDisabled: rgba(baseColors.neutral000, 0.6),
      buttonBackgroundCriticalDefault: rgb(baseColors.neutral000),
      buttonBackgroundCriticalHovered: rgb(baseColors.red300),
      buttonBackgroundCriticalPressed: rgb(baseColors.red400),
      buttonBackgroundCriticalDisabled: rgba(baseColors.neutral000, 0.6),
      buttonLabelBaseDefault: rgb(baseColors.neutral900),
      buttonLabelBaseHovered: rgb(baseColors.neutral500),
      buttonLabelBasePressed: rgb(baseColors.neutral700),
      buttonLabelBaseDisabled: rgba(baseColors.neutral500, 0.6),
      buttonLabelOnAccentDefault: rgb(baseColors.neutral000),
      buttonLabelOnAccentHovered: rgb(baseColors.neutral100),
      buttonLabelOnAccentDisabled: rgba(baseColors.neutral000, 0.6),
      buttonLabelOnSecondaryDefault: rgb(baseColors.blue500),
      buttonLabelOnSecondaryHovered: rgb(baseColors.neutral500),
      buttonLabelOnSecondaryDisabled: rgba(baseColors.neutral500, 0.6),
      buttonLabelCriticalDefault: rgb(baseColors.red500),
      buttonLabelCriticalHovered: rgb(baseColors.red600),
      buttonLabelCriticalPressed: rgb(baseColors.red700),
      buttonLabelCriticalDisabled: rgb(baseColors.red400),
      buttonIconBaseDefault: rgb(baseColors.neutral900),
      buttonIconBaseHovered: rgb(baseColors.neutral500),
      buttonIconBasePressed: rgb(baseColors.neutral700),
      buttonIconBaseDisabled: rgba(baseColors.neutral500, 0.6),
      buttonIconOnAccentDefault: rgb(baseColors.neutral000),
      buttonIconOnAccentHovered: rgb(baseColors.neutral100),
      buttonIconOnAccentDisabled: rgba(baseColors.neutral000, 0.6),
      buttonIconOnSecondaryDefault: rgb(baseColors.blue500),
      buttonIconOnSecondaryHovered: rgb(baseColors.neutral500),
      buttonIconOnSecondaryDisabled: rgba(baseColors.neutral500, 0.6),
      buttonIconCriticalDefault: rgb(baseColors.red500),
      buttonIconCriticalHovered: rgb(baseColors.red600),
      buttonIconCriticalPressed: rgb(baseColors.red700),
      buttonIconCriticalDisabled: rgb(baseColors.red400),
      buttonIconSpinner: gradient.gradientAngular,

      yellowDecorativeHover: baseColors.yellow,
      purpleDecorativeHover: baseColors.purple,
    },
    fontWeights: {
      bold: "700",
      medium: "500",
      regular: "400",
    },
    fonts,
    fontSizes,
    // Root size : 16px
    spacing: {
      /** 0px */
      "0": "0",
      /** 2px */
      [`spacing2px`]: ".125rem",
      /** 4px */
      [`spacing4px`]: ".25rem",
      /** 8px */
      [`spacing8px`]: ".5rem",
      /** 12px */
      [`spacing12px`]: ".75rem",
      /** 16px */
      [`spacing16px`]: "1rem",
      /** 20px */
      [`spacing20px`]: "1.25rem",
      /** 22px */
      [`spacing22px`]: "1.375rem",
      /** 24px */
      [`spacing24px`]: "1.5rem",
      /** 28px */
      [`spacing28px`]: "1.75rem",
      /** 32px */
      [`spacing32px`]: "2rem",
      /** 36px */
      [`spacing36px`]: "2.25rem",
      /** 40px */
      [`spacing40px`]: "2.5rem",
      /** 44px */
      [`spacing44px`]: "2.75rem",
      /** 48px */
      [`spacing48px`]: "3rem",
      /** 64px */
      [`spacing64px`]: "4rem",
      /** 80px */
      [`spacing80px`]: "5rem",
      /** 96px */
      [`spacing96px`]: "6rem",
      /** 128px */
      [`spacing128px`]: "8rem",
      /** 160px */
      [`spacing160px`]: "10rem",
      /** 192px */
      [`spacing192px`]: "12rem",
      /** 216px */
      [`spacing216px`]: "13.5rem",
      /** 224px */
      [`spacing224px`]: "14rem",
      /** 256px */
      [`spacing256px`]: "16rem",
    },
    elevation: shadows,
    letterSpacings: {
      regular: "-0.02em",
    },
    radii: {
      1: "0.125rem",
      4: "0.25rem",
      7: "0.4375rem",
      8: "0.5rem",
      9: "0.5625rem",
      12: "0.75rem",
      16: "1rem",
      20: "1.25rem",
      24: "1.5rem",
      32: "2rem",
      100: "6.25rem",
    },
    boxShadow: {
      hovered: `0px 0px 0px 4px ${rgb(baseColors.neutral200)}`,
      selected: `0px 0px 0px 4px ${rgb(baseColors.blue300)}`,
      criticalSelected: `0px 0px 0px 4px ${rgb(baseColors.red300)}`,
      focus: `0px 0px 0px 2px ${rgb(baseColors.blue500)}`,
    },
    outline: {
      base: `1px solid ${rgb(baseColors.blue300)}`,
      disabled: `1px solid ${rgb(baseColors.blue300)}`,
      hovered: `1px solid ${rgb(baseColors.blue300)}`,
      pressed: `1px solid ${rgb(baseColors.neutral400)}`,
      selected: `2px solid ${rgb(baseColors.blue500)}`,
      critical: `1.5px solid ${rgb(baseColors.red500)}`,
      criticalPressed: `1px solid ${rgb(baseColors.red500)}`,
      criticalHovered: `1px solid ${rgb(baseColors.red400)}`,
    },
  },
});

// We need to export this like that because of css var using with the theme
export const textColors = Object.freeze({
  textBaseDefault: theme.colors.textBaseDefault,
  textBaseSubdued: theme.colors.textBaseSubdued,
  textBaseDisabled: theme.colors.textBaseDisabled,
  textPrimaryDefault: theme.colors.textPrimaryDefault,
  textPrimaryDisabled: theme.colors.textPrimaryDisabled,
  textAccent: theme.colors.textAccent,
  textSuccess: theme.colors.textSuccess,
  textSuccesAccent: theme.colors.textSuccesAccent,
  textSuccessDisabled: theme.colors.textSuccessDisabled,
  textCritical: theme.colors.textCritical,
  textCriticalAccent: theme.colors.textCriticalAccent,
  textCriticalDisabled: theme.colors.textCriticalDisabled,
  textProgress: theme.colors.textProgress,
  textWarning: theme.colors.textWarning,
  textWarningDisabled: theme.colors.textWarningDisabled,
  textSecondaryDefault: theme.colors.textSecondaryDefault,
  textSecondarySubdued: theme.colors.textSecondarySubdued,
  textSecondaryDisabled: theme.colors.textSecondaryDisabled,
  textLinkBlue: theme.colors.textLinkBlue,
});

// We need to export this like that because of css var using with the theme
export const buttonLabelColors = Object.freeze({
  buttonLabelBaseDefault: theme.colors.buttonLabelBaseDefault,
  buttonLabelBaseHovered: theme.colors.buttonLabelBaseHovered,
  buttonLabelBasePressed: theme.colors.buttonLabelBasePressed,
  buttonLabelBaseDisabled: theme.colors.buttonLabelBaseDisabled,
  buttonLabelOnAccentDefault: theme.colors.buttonLabelOnAccentDefault,
  buttonLabelOnAccentHovered: theme.colors.buttonLabelOnAccentHovered,
  buttonLabelOnAccentDisabled: theme.colors.buttonLabelOnAccentDisabled,
  buttonLabelOnSecondaryDefault: theme.colors.buttonLabelOnSecondaryDefault,
  buttonLabelOnSecondaryHovered: theme.colors.buttonLabelOnSecondaryHovered,
  buttonLabelOnSecondaryDisabled: theme.colors.buttonLabelOnSecondaryDisabled,
  buttonLabelCriticalDefault: theme.colors.buttonLabelCriticalDefault,
  buttonLabelCriticalHovered: theme.colors.buttonLabelCriticalHovered,
  buttonLabelCriticalPressed: theme.colors.buttonLabelCriticalPressed,
  buttonLabelCriticalDisabled: theme.colors.buttonLabelCriticalDisabled,
});

export const iconColors = Object.freeze({
  iconBaseDefault: theme.colors.iconBaseDefault,
  iconBaseSubdued: theme.colors.iconBaseSubdued,
  iconBaseDisabled: theme.colors.iconBaseDisabled,
  iconPrimaryDefault: theme.colors.iconPrimaryDefault,
  iconPrimaryHovered: theme.colors.iconPrimaryHovered,
  iconPrimaryPressed: theme.colors.iconPrimaryPressed,
  iconPrimaryDisabled: theme.colors.iconPrimaryDisabled,
  iconPrimaryMuted: theme.colors.iconPrimaryMuted,
  iconAccent: theme.colors.iconAccent,
  iconSecondaryDefault: theme.colors.iconSecondaryDefault,
  iconSecondarySubdued: theme.colors.iconSecondarySubdued,
  iconSecondaryDisabled: theme.colors.iconSecondaryDisabled,
  iconSuccess: theme.colors.iconSuccess,
  iconWarning: theme.colors.iconWarning,
  iconCritical: theme.colors.iconCritical,
  iconDecorativeGreen: theme.colors.iconDecorativeGreen,
  iconDecorativeLightBlue: theme.colors.iconDecorativeLightBlue,
  iconDecorativeRed: theme.colors.iconDecorativeRed,
  iconDecorativePurple: theme.colors.iconDecorativePurple,
  iconGreyDefault: theme.colors.iconGreyDefault,
});

export const backgroundColors = Object.freeze({
  fillBaseAccent: theme.colors.fillBaseAccent,
  fillBaseDefault: theme.colors.fillBaseDefault,
  fillBaseSubdued: theme.colors.fillBaseSubdued,
  fillBaseHovered: theme.colors.fillBaseHovered,
  fillBasePressed: theme.colors.fillBasePressed,
  fillBaseDisabled: theme.colors.fillBaseDisabled,
  fillOverlay: theme.colors.fillOverlay,
  fillPrimaryDefault: theme.colors.fillPrimaryDefault,
  fillPrimaryHovered: theme.colors.fillPrimaryHovered,
  fillPrimaryPressed: theme.colors.fillPrimaryPressed,
  fillPrimarySubdued: theme.colors.fillPrimarySubdued,
  fillPrimaryDisabled: theme.colors.fillPrimaryDisabled,
  fillAccent: theme.colors.fillAccent,
  fillSecondary: theme.colors.fillSecondary,
  fillSecondarySubdued: theme.colors.fillSecondarySubdued,
  fillSecondaryPressed: theme.colors.fillSecondaryPressed,
  fillSuccessAccent: theme.colors.fillSuccessAccent,
  fillSuccessSubdued: theme.colors.fillSuccessSubdued,
  fillWarning: theme.colors.fillWarning,
  fillWarningSubdued: theme.colors.fillWarningSubdued,
  fillCriticalAccent: theme.colors.fillCriticalAccent,
  fillCriticalSubdued: theme.colors.fillCriticalSubdued,
  fillDecorativeGreen: theme.colors.fillDecorativeGreen,
  fillDecorativeLightBlue: theme.colors.fillDecorativeLightBlue,
  fillDecorativeRed: theme.colors.fillDecorativeRed,
  fillDecorativePurple: theme.colors.fillDecorativePurple,
  fillDecorativeYellow: theme.colors.fillDecorativeYellow,
  fillDecorativeWhite: theme.colors.fillDecorativeWhite,
});

// à voir comment l'intégrer sans casser le reste du site
export const globalStyles = globalCss({
  "#root": {
    color: textColors.textBaseDefault,
  },
});
