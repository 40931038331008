import copy from "copy-to-clipboard";
import { Icon } from "src/components/01_atoms/icon";
import { Text } from "src/components/01_atoms/text";
import { toast } from "src/components/01_atoms/toast";
import { Modal } from "src/components/04_layouts/modal-layout";
import { matchIsAllContractsDeffered } from "src/helpers";
import { useGetContract } from "src/hooks/data";
import { useRafOperation } from "src/hooks/use-raf-operation";
import { useRefundSidebar } from "src/hooks/use-refund-sidebar";
import { useDevice } from "src/hooks/useDevice";
import { MediaCardSponsorship } from "../media-card-sponsorship/media-card-sponsorship";
import {
  ContainerTitle,
  Description,
  ExplanationSection,
  HeaderModal,
  HeaderSeparator,
  Illustration,
  Img,
  Note,
  Number,
  RafShareButton,
  ReferIllustration,
  SponsorshipCard,
  Step,
  StepBorder,
  StepDescription,
  StepNumber,
  StepSeparator,
  StyledButton,
  TitleExplanation,
  WrapperContent,
  WrapperExplanationSection,
  WrapperIcon,
  WrapperNoteText,
  WrapperStepDescription,
  WrapperText,
} from "./referral-modal.styled";

type ReferralModalProps = {
  isRafFModalOpen: boolean;
  setIsRAFModalOpen: (isOpen: boolean) => void;
  rafCode?: string;
};

export function ReferralModal({ isRafFModalOpen, setIsRAFModalOpen, rafCode }: ReferralModalProps) {
  const { isMobile } = useDevice();
  const { isOperation200Enabled } = useRafOperation();
  const { data: contracts } = useGetContract();
  const { selectedPetToContext } = useRefundSidebar();

  return (
    <Modal
      isOpen={isRafFModalOpen}
      label="test"
      onDismiss={() => setIsRAFModalOpen(false)}
      fullScreen
    >
      <WrapperContent>
        {isMobile() ? (
          <HeaderModal>
            <Text variant="paragraph1-Bold" color="textBaseDefault">
              Parrainez vos proches
            </Text>
            <WrapperIcon onClick={() => setIsRAFModalOpen(false)}>
              <Icon name="outlinedClose" size="24" />
            </WrapperIcon>
            <HeaderSeparator />
          </HeaderModal>
        ) : null}
        {isMobile() ? (
          <MediaCardSponsorship setIsRAFModalOpen={setIsRAFModalOpen} isFromModal />
        ) : (
          <SponsorshipCard operation={isOperation200Enabled}>
            {}
            <Text variant="paragraph1-Bold" color="textSecondaryDefault" textAlign="center">
              Parrainez vos proches
            </Text>
            {}
            <Img
              src={
                isOperation200Enabled
                  ? "/assets/media/referral-illu-operation-200.svg"
                  : "/assets/media/referral-illustration.svg"
              }
              alt={isOperation200Enabled ? "Roue de loterie" : "Faux billet de banque"}
            />
            <WrapperText>
              {isOperation200Enabled ? (
                <>
                  <ContainerTitle>
                    <span>Gagnez jusqu’à 200€</span>
                    <br />
                    <span>en parrainant vos proches</span>
                  </ContainerTitle>
                  <Description variant="paragraph1-Medium" color="textSecondaryDefault">
                    Empochez 20€, 50€, 100€ ou même 200€ pour chaque ami parrainé !
                  </Description>
                </>
              ) : (
                <>
                  <ContainerTitle>
                    <span>Recevez </span>
                    <span className="stroked">25€ </span>
                    <span>50€</span>
                    <br />
                    <span>pour chaque parrainage !</span>
                  </ContainerTitle>
                  <Description variant="paragraph1-Medium" color="textSecondaryDefault">
                    50€ pour vous, 1 mois offert pour vos proches
                  </Description>
                </>
              )}
            </WrapperText>
          </SponsorshipCard>
        )}
        <WrapperExplanationSection>
          <TitleExplanation variant="display4-Bold" color="textBaseDefault">
            Comment parrainer un proche ?
          </TitleExplanation>
          <ExplanationSection>
            <Step>
              <StepNumber>
                <Number variant="paragraph3-Bold">1</Number>
                <StepBorder />
              </StepNumber>
              <WrapperStepDescription>
                <StepDescription variant="paragraph2-Medium">
                  Partagez votre lien de parrainage à un maximum de parents de chiens et chats de
                  votre entourage.
                </StepDescription>
                <StepSeparator />
              </WrapperStepDescription>
            </Step>
            <Step>
              <StepNumber>
                <Number variant="paragraph3-Bold">2</Number>
                <StepBorder />
              </StepNumber>
              <WrapperStepDescription>
                <StepDescription variant="paragraph2-Medium">
                  Votre proche doit souscrire à Dalma via ce lien. Son mois offert sera directement
                  appliqué !
                </StepDescription>
                <StepSeparator />
              </WrapperStepDescription>
            </Step>
            <Step>
              <StepNumber>
                <Number variant="paragraph3-Bold">3</Number>
                <StepBorder />
              </StepNumber>
              <WrapperStepDescription>
                <StepDescription variant="paragraph2-Medium">
                  Attendez que votre proche passe sa période de rétractation (14 jours)
                </StepDescription>
                <StepSeparator />
              </WrapperStepDescription>
            </Step>
            {isOperation200Enabled ? (
              <Step>
                <ReferIllustration src="/assets/media/gift.svg" alt="Illustration d'un cadeau" />
                <div style={{ flexDirection: "column" }}>
                  <Text variant="paragraph1-Bold" style={{ marginBottom: "4px" }}>
                    Un prix assuré !
                  </Text>
                  <StepDescription variant="paragraph2-Medium">
                    Empochez 20€, 50€, 100€ ou même 200€ pour chaque ami parrainé !
                  </StepDescription>
                </div>
              </Step>
            ) : (
              <Step>
                <StepNumber>
                  <Number variant="paragraph3-Bold">4</Number>
                </StepNumber>
                <StepDescription variant="paragraph2-Medium">
                  Recevez 50€ et la gratitude éternelle de votre proche
                </StepDescription>
              </Step>
            )}
          </ExplanationSection>

          <Note>
            <Illustration src="/assets/media/illu-loup.svg" alt="Loupe design" />
            <WrapperNoteText>
              <Text variant="paragraph1-Bold">Bon à savoir :</Text>
              <Text variant="paragraph2-Medium">
                Si votre proche perd le lien ou passe directement par notre site, il peut utiliser
                votre code parrain personnel au moment du paiement: <br />
                {contracts && !matchIsAllContractsDeffered(contracts) && rafCode}
              </Text>
              {contracts ? (
                !matchIsAllContractsDeffered(contracts) ? (
                  <>
                    <StyledButton
                      type="button"
                      variant="secondary"
                      size="sm"
                      iconRight="filledCopy"
                      fluid
                      onClick={() => {
                        if (rafCode) {
                          copy(
                            `Hello,\nRejoins-moi chez Dalma, et profite de ton 1er mois gratuit en renseignant mon code parrain au moment du paiement : ${rafCode}`
                          );

                          toast.copy("Le code a été copié dans le presse-papier");
                        }
                      }}
                      disabled={!rafCode}
                      aria-label="Copier votre code de parrainage"
                    >
                      Copier le code
                    </StyledButton>
                  </>
                ) : null
              ) : null}
            </WrapperNoteText>
          </Note>

          {contracts ? (
            matchIsAllContractsDeffered(contracts) ? (
              <>
                <WrapperNoteText style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Text variant="paragraph2-Medium" style={{ textAlign: "center" }}>
                      Disponible dès la prise d’effet de votre contrat
                    </Text>
                  </div>
                </WrapperNoteText>
              </>
            ) : null
          ) : null}
          <RafShareButton
            type="button"
            variant="accent"
            size="md"
            fluid
            disabled={contracts ? matchIsAllContractsDeffered(contracts) : false}
            onClick={() => {
              if (rafCode) {
                copy(
                  `Hello !\n Rejoins-moi chez Dalma avec ta boule de poils et profite de ton 1er mois gratuit. Pour ça, clique sur mon lien : 
                  https://parrain.dalma.co/?raf_code=${encodeURI(
                    rafCode
                  )}&utm_source=RAF&utm_medium=&utm_campaign=page-filleul
                  En cas de problème, tu peux renseigner mon code ${rafCode} au moment du paiement.`
                );
                toast.copy("Votre code de parrainage a été copié dans le presse-papier");
              }
            }}
            aria-label="Copier votre code de parrainage"
          >
            {isOperation200Enabled ? "Partager mon lien" : "Copier mon lien de parrainage"}
          </RafShareButton>
          {isOperation200Enabled ? (
            <Text
              variant="paragraph1-Regular"
              style={{ margin: "1.5rem 0" }}
              color="textBaseDefault"
              textAlign="center"
            >
              Consulter les{" "}
              <a
                href="https://editique.s3.eu-west-3.amazonaws.com/public/Reglement_parrainage_Dalma_fr.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#4E68F6", fontWeight: 700 }}
              >
                Règles et Condition
              </a>{" "}
              du jeu concours Dalma.
            </Text>
          ) : null}
        </WrapperExplanationSection>
      </WrapperContent>
    </Modal>
  );
}
