import { theme, styled } from "src/styles/stitches/theme";
import { Button } from "src/components/01_atoms/button";
import { Text } from "src/components/01_atoms/text";

export const WrapperContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: theme.spacing.spacing24px,

  "@bp3": {
    justifyContent: "space-around",
    flexDirection: "row",
    padding: theme.spacing.spacing48px,
  },
});

export const SponsorshipCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 50%",
  backgroundImage: "",
  backgroundSize: "cover",
  width: "33rem",
  height: "100%",
  borderRadius: theme.spacing.spacing20px,
  paddingTop: theme.spacing.spacing32px,

  variants: {
    operation: {
      true: {
        backgroundImage: "url(/assets/media/background-operation-200.png)",
      },
      false: {
        backgroundImage: "url(/assets/media/background-sponsorship-card.png)",
      },
    },
  },
});

export const WrapperText = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "4.688rem",
});

export const Img = styled("img", {
  width: "15rem",
  height: "15rem",
  marginTop: "6.25rem",
});

export const ContainerTitle = styled("div", {
  textAlign: "center",
  margin: "1rem 0",
  span: {
    margin: 0,
    padding: 0,
    fontSize: theme.fontSizes.textDisplay2,
    lineHeight: 1.153,
    fontWeight: 700,
    letterSpacing: theme.letterSpacings.regular,
    color: "white",
  },
  ".stroked": {
    textDecoration: "line-through",
    opacity: 0.3,
  },
});

export const Description = styled(Text, {
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  marginBottom: "1rem",

  "@bp3": {
    marginBottom: 0,
    textAlign: "start",
  },
});

export const WrapperExplanationSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "27rem",

  "@bp3": {
    marginBottom: 0,
    textAlign: "start",
  },
});

export const TitleExplanation = styled(Text, {
  margin: theme.spacing.spacing24px,
  fontSize: "1.25rem !important",
  "@bp3": {
    marginBottom: theme.spacing.spacing24px,
  },
});

export const ExplanationSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  boxShadow: theme.elevation.elevation1Purple,
  border: "1px solid #DBEBFF",
  borderRadius: theme.spacing.spacing24px,
});

export const Step = styled("div", {
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing.spacing24px} ${theme.spacing.spacing16px}`,
});

export const StepBorder = styled("span", {
  position: "absolute",
  transform: "translateX(-50%)",
  left: "50%",
  top: "115%",
  height: "2.688rem",
  width: "0.125rem",
  backgroundColor: "#678AB8",
  borderRadius: "0.375rem",
  display: "none",

  "@bp3": {
    display: "block",
  },
});

export const StepNumber = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "2.688rem",
  height: "2rem",
  backgroundColor: "#DBEBFF",
  borderRadius: "100px",
  marginRight: theme.spacing.spacing24px,

  "@bp3": {
    width: "2rem",
  },
});

export const StepDescription = styled(Text, {
  maxWidth: "21.875rem",
});

export const Number = styled(Text, {});

export const Note = styled("div", {
  display: "flex",
  boxShadow: theme.elevation.elevation1Purple,
  padding: theme.spacing.spacing16px,
  borderRadius: theme.spacing.spacing16px,
  margin: "1.5rem 0px",
  width: "100%",
  border: "1px solid #DBEBFF",
});

export const WrapperStepDescription = styled("div", {
  position: "relative",
});

export const StepSeparator = styled("span", {
  position: "absolute",
  width: "100%",
  height: "1px",
  bottom: "-60%",
  backgroundColor: "#DBEBFF",
});

export const HeaderSeparator = styled("span", {
  position: "absolute",
  width: "100%",
  height: "1px",
  top: "4.688rem",
  backgroundColor: "#DBEBFF",
});

export const WrapperNoteText = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "0.375rem",
});

export const Illustration = styled("img", {
  width: "4rem",
  height: "4rem",
  marginRight: theme.spacing.spacing12px,
});

export const StyledButton = styled(Button, {
  border: "none !important",
  display: "flex",
  alignSelf: "start",
  padding: "0 !important",
  margin: "0 !important",

  "&:hover": {
    backgroundColor: "transparent !important",
  },
});

export const HeaderModal = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing.spacing16px,
  marginBottom: theme.spacing.spacing24px,
});

export const WrapperIcon = styled("div", {
  position: "absolute",
  right: theme.spacing.spacing24px,
});

export const RafShareButton = styled(Button, {
  marginBottom: theme.spacing.spacing24px,

  "@bp3": {
    marginBottom: 0,
  },
});

export const ReferIllustration = styled("img", {
  height: "2.5rem",
  width: "2.5rem",
  objectFit: "contain",
  objectPosition: "center",
  marginRight: theme.spacing.spacing16px,
});
