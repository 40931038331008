import { useHistory } from "react-router-dom";
import { AnchorButton, Button } from "src/components/01_atoms/button";
import { Outlet } from "src/components/01_atoms/outlet";
import { useLogOut } from "src/hooks/signin/_use-log-out";
import { routes } from "src/routes";
import { Tooltip } from "src/components/tooltip";

export function RightSidebarLinks() {
  const history = useHistory();
  const handleLogout = useLogOut();

  return (
    <Outlet vertical="spacing16px">
      <AnchorButton
        variant="smallActionBase"
        size="sm"
        href="https://intercom.help/dalma/fr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Aide, Support
      </AnchorButton>
      <Tooltip size={14} message={"Le parrainage sera disponible une fois votre contrat actif."}>
        <Button variant="smallActionBase" size="sm" onClick={() => history.push(routes.sponsorhip)}>
          Parrainer un proche
        </Button>
      </Tooltip>
      <Button variant="smallActionCritical" size="sm" onClick={handleLogout}>
        Se déconnecter
      </Button>
    </Outlet>
  );
}
